import React, { useState } from 'react';
import AppMain from "./Components/Main";
import "./Components/main.css";
import { APP_KEY } from './config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';

const Main = () => {
  const [accessKey, setAccessKey] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleAccessKeyChange = (event) => {
    setAccessKey(event.target.value);
  };

  const handleLogin = () => {
    if (accessKey === APP_KEY) {
      setIsLoggedIn(true);
    }
  };

  return (
    <div>
      <div className="sidebar">
        <div className="sidebar-icon">
          <a href="https://github.com/JMCromp" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </div>
        <div className="sidebar-icon">
          <a href="https://www.linkedin.com/in/jordanmarkcrompton/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>
      </div>
      <div className="login-container">
        {!isLoggedIn ? (
          <div className="login-box">
            <h2>Enter Access Key</h2>
            <input
              type="text"
              value={accessKey}
              onChange={handleAccessKeyChange}
              className="access-key-input"
            />
            <button onClick={handleLogin} className="login-button">
              Login
            </button>
          </div>
        ) : (
          <div>
            {<AppMain />}
          </div>
        )}
      </div>
    </div>
  );
};

export default Main;
