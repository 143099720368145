import React, { useState, useRef, useEffect } from 'react';
import { OpenAIApi, Configuration } from 'openai';
import { API_KEY } from '../config';
import './chatwindow.css';

const configuration = new Configuration({
  apiKey: API_KEY,
});
const openai = new OpenAIApi(configuration);

delete configuration.baseOptions.headers['User-Agent'];

const ChatWindow = () => {
  const [userInput, setUserInput] = useState('');
  const [conversation, setConversation] = useState([]);
  const [completion, setCompletion] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isFirefoxOrOpera, setIsFirefoxOrOpera] = useState(false);
  const [isRecording, setIsRecording] = useState(false);

  const audioRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);

  const [voices, setVoices] = useState([]);

  useEffect(() => {
    // Preload the voices when the component mounts
    if (window.speechSynthesis && window.speechSynthesis.onvoiceschanged !== undefined) {
      window.speechSynthesis.onvoiceschanged = () => {
        setVoices(window.speechSynthesis.getVoices());
      };
    } else {
      setVoices(window.speechSynthesis.getVoices());
    }
  }, []);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.includes('firefox') || userAgent.includes('opr/') || userAgent.includes('opera')) {
      setIsFirefoxOrOpera(true);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    handleFormSubmit();
  };

  const handleFormSubmit = async () => {
    if (userInput.trim() === '') return;

    const newMessage = { role: 'user', content: userInput };
    const updatedConversation = [...conversation, newMessage];
    setConversation(updatedConversation);

    try {
      const response = await openai.createChatCompletion({
        model: 'gpt-3.5-turbo',
        messages: updatedConversation,
        max_tokens: 2000,
        temperature: 0.8,
        presence_penalty: 0.6,
      });

      const completion = response.data.choices[0].message.content.trim();
      setCompletion(completion);
      speakText(completion); // Autoplay the response
    } catch (error) {
      console.error('Error:', error.message);
      if (error.response && error.response.status === 429) {
        setErrorMessage('Too many requests. Please wait and try again...');
      } else {
        setErrorMessage('An error occurred. Please try again...');
      }
    }

    setUserInput('');
  };

  const startCapture = () => {
    setIsRecording(true)
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((stream) => {
        mediaRecorderRef.current = new MediaRecorder(stream);
        mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);
        mediaRecorderRef.current.addEventListener('stop', handleStop);
        chunksRef.current = []; // Clear the chunks array
        mediaRecorderRef.current.start();
      })
      .catch((error) => {
        console.error('Error:', error.message);
        setErrorMessage(error.message);
      });
  };

  const stopCapture = () => {
    setIsRecording(false)
    if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
      mediaRecorderRef.current.addEventListener('stop', handleStop);
      mediaRecorderRef.current.stop();
    }
  };

  const handleDataAvailable = (event) => {
    chunksRef.current.push(event.data);
  };

  const handleStop = () => {
    mediaRecorderRef.current.removeEventListener('stop', handleStop);
    handleVoiceData();
    chunksRef.current = []; // Clear the chunks array
    audioRef.current.src = null; // Reset the audio source
  };

  const handleVoiceData = async () => {
    const blob = new Blob(chunksRef.current, { type: 'audio/webm' });
    const transcribedText = await convertBlobToString(blob);
    setUserInput(transcribedText);
  };

  const convertBlobToString = async (blob) => {
    const audioURL = URL.createObjectURL(blob);
    const audio = new Audio(audioURL);

    return new Promise((resolve, reject) => {
      let SpeechRecognition =
        window.SpeechRecognition ||
        window.webkitSpeechRecognition ||
        window.mozSpeechRecognition ||
        window.msSpeechRecognition;
      if (!SpeechRecognition) {
        setErrorMessage('Speech recognition is not supported in this browser.');
        reject(new Error('Speech recognition is not supported in this browser.'));
        return;
      }
      const recognition = new SpeechRecognition();
      recognition.lang = 'en-US';
      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        resolve(transcript);
      };
      recognition.onerror = (event) => {
        reject(event.error);
      };

      audio.addEventListener('ended', () => {
        recognition.stop();
      });

      audio.addEventListener('loadeddata', () => {
        recognition.start();
        audio.play();
      });

      audio.addEventListener('error', (event) => {
        reject(event.error);
      });

      audio.play();
    });
  };

  const speakText = (text) => {
    try {
      const utterance = new SpeechSynthesisUtterance();
      const voiceName = 'Google UK English Female';
      let selectedVoice = voices.find((voice) => voice.name === voiceName);

      if (!selectedVoice) {
        console.warn(`Voice '${voiceName}' not found. Using default voice.`);
        setErrorMessage(`Voice '${voiceName}' not found. Now using default voice.`);
        utterance.voice = null; // Set voice to null
      } else {
        utterance.voice = selectedVoice;
      }

      // Split the text into smaller chunks
      const maxChunkSize = 200; // Adjust the chunk size as needed
      const chunks = [];
      for (let i = 0; i < text.length; i += maxChunkSize) {
        chunks.push(text.substring(i, i + maxChunkSize));
      }

      const playChunksSequentially = (index) => {
        if (index >= chunks.length) {
          return; // Finished playing all chunks
        }

        utterance.text = chunks[index];
        utterance.onend = () => {
          playChunksSequentially(index + 1); // Play the next chunk
        };

        window.speechSynthesis.speak(utterance);
      };

      playChunksSequentially(0); // Start playing the chunks sequentially
    } catch (error) {
      console.error('Error:', error.message);
      setErrorMessage(error.message);
      // You can handle the error here, such as updating the state or displaying an error message in the form
    }
  };

  return (
    <div className='gpttts-container'>
      <div>
        <p><b>Type your questions below, or use the 'start' and 'stop' capture buttons to transcribe your voice.</b></p>
        <div className="chatBox">
          {conversation.map((message, index) => (
            <div key={index} className={`message ${message.role}`}>
              <p>{"user: " + message.content}</p>
            </div>
          ))}
          {completion && (
            <div className="message assistant">
              <p>{"assistant: " + completion}</p>
            </div>
          )}
        </div>
        <form className='inputForm' onSubmit={handleSubmit}>
          <textarea
            className='inputArea'
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
          />
          <button className='recordButton sendButton' type="submit">Send</button>
        </form>
      </div>
      {errorMessage && <p className="error"><b>Error:</b> {errorMessage}</p>}
      {isFirefoxOrOpera ? (
        <>
          <p>Please use Chrome to enable voice capture.</p>
        </>
      ) : (
        <div>
          {isRecording ? (
            <>
              <p>Recording in progress...</p>
              <button className='recordButton' onClick={stopCapture}>Stop recording</button>
            </>
          ) : (
            <button className='recordButton' onClick={startCapture}>Start recording</button>
          )}
          <audio className='audio-box' ref={audioRef} controls />
        </div>
      )}
    </div>
  );
};

export default ChatWindow;
