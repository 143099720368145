import './enigma.css';

const Enigma = () => {

        return (
            <div>
                <iframe className='enigma-frame' src="https://jmcromp.github.io/enigma/"></iframe>
            </div>
        );
};

export default Enigma;