import React, { useEffect, useRef, useCallback } from 'react';
import './compvision.css';
import * as tf from '@tensorflow/tfjs';
import * as cocoSsd from '@tensorflow-models/coco-ssd';

const Compvision = () => {
  const webcamRef = useRef(null);
  const liveViewRef = useRef(null);
  const webcamButtonRef = useRef(null);
  const ptextRef = useRef(null);
  const modelRef = useRef(null);
  const childRef = useRef([]);

  const webcamOutput = useCallback(() => {
    if (!modelRef.current) {
      return;
    }

    modelRef.current.detect(webcamRef.current).then((confidence) => {
      const liveView = liveViewRef.current;

      for (let i = 0; i < childRef.current.length; i++) {
        liveView.removeChild(childRef.current[i]);
      }
      childRef.current.splice(0);

      for (let x = 0; x < confidence.length; x++) {
        if (confidence[x].score > 0.5) {
          const p = document.createElement('p');
          p.innerText = `${confidence[x].class} - with ${Math.round(parseFloat(confidence[x].score) * 100)}% confidence.`;
          p.style = `margin-left: ${confidence[x].bbox[0]}px; margin-top: ${confidence[x].bbox[1] - 10}px; width: ${confidence[x].bbox[2] - 10}px; top: 0; left: 0;`;
          const highlighter = document.createElement('div');
          highlighter.setAttribute('class', 'highlighter');
          highlighter.style = `left: ${confidence[x].bbox[0]}px; top: ${confidence[x].bbox[1]}px; width: ${confidence[x].bbox[2]}px; height: ${confidence[x].bbox[3]}px;`;

          liveView.appendChild(p);
          liveView.appendChild(highlighter);
          childRef.current.push(p);
          childRef.current.push(highlighter);
        }
      }

      window.requestAnimationFrame(webcamOutput);
    });
  }, []);

  useEffect(() => {
    let modelPromise;

    const loadModel = async () => {
      await tf.setBackend('webgl');
      await tf.ready();
      modelPromise = cocoSsd.load();
      const model = await modelPromise;
      modelRef.current = model;
    };

    loadModel();

    return () => {
      // Cleanup function
    };
  }, []);

  const getUserMedia = useCallback(() => {
    return !!(navigator.getUserMedia && navigator.mediaDevices.getUserMedia);
  }, []);

  useEffect(() => {
    const webcamEnable = () => {
      if (!modelRef.current) {
        return;
      }

      const buttonRef = webcamButtonRef.current;
      if (buttonRef) {
        buttonRef.classList.add('removed');
        ptextRef.current.classList.add('removed');

        const webcamProperties = {
          video: true
        };

        navigator.mediaDevices.getUserMedia(webcamProperties).then((stream) => {
          const webcamRefCopy = webcamRef.current;
          if (webcamRefCopy) {
            webcamRefCopy.srcObject = stream;
            webcamRefCopy.addEventListener('loadeddata', webcamOutput);
          }
        });
      }
    };

    if (getUserMedia() && webcamButtonRef.current) {
      webcamButtonRef.current.addEventListener('click', webcamEnable);
    }

    return () => {
      if (getUserMedia() && webcamButtonRef.current) {
        const buttonRef = webcamButtonRef.current;
        buttonRef.removeEventListener('click', webcamEnable);
      }

      const webcamRefCopy = webcamRef.current;
      if (webcamRefCopy) {
        webcamRefCopy.removeEventListener('loadeddata', webcamOutput);
      }
    };
  }, [getUserMedia, webcamOutput]);

  return (
    <div className='videoContainer'>
      <section>
        <div id="liveView" className="videoView" ref={liveViewRef}>
          <button id="webcamButton" ref={webcamButtonRef}>
            Enable Webcam
          </button>
          <video id="webcam" autoPlay ref={webcamRef}></video>
          <p ref={ptextRef}>'Enable Webcam' button will become clickable once dependencies have successfully loaded. After load, the model will classify entities, objects, and images if held to the camera. Typically, the best experience is in a Chromium-based browser.</p>
        </div>
      </section>
    </div>
  );
};

export default Compvision;
