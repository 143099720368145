import './feedback.css';
import React, { useState, useEffect } from 'react';
import { OpenAIApi, Configuration } from 'openai';
import { API_KEY } from '../config';

const configuration = new Configuration({
    apiKey: API_KEY,
});
const openai = new OpenAIApi(configuration);

delete configuration.baseOptions.headers['User-Agent'];

function Feedback() {
    const [userInput, setUserInput] = useState('');
    const [conversation, setConversation] = useState([]);
    const [completion, setCompletion] = useState('');
    const [error, setError] = useState('');

    const [learnerName, setLearnerName] = useState('');
    const [learnerPronouns, setLearnerPronouns] = useState('');
    const [customPrompts, setCustomPrompts] = useState('');
    const [question, setQuestion] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        handleFormSubmit();
    };

    const handleFormSubmit = async () => {
        if (userInput.trim() === '') return;

        const newMessage = { role: 'user', content: userInput };
        // const updatedConversation = [...conversation, newMessage];
        const updatedConversation = [newMessage];
        setConversation(updatedConversation);
        // before you forget why you did this... clears completion on second submit until response generated
        // temp solution to minimise horizontal output, above commented line is the same story
        setCompletion("Response generating...")
        setIsLoading(true);

        try {
            const response = await openai.createChatCompletion({
                model: 'gpt-3.5-turbo',
                messages: [
                    ...updatedConversation,
                    {
                        role: 'assistant',
                        content: `You are a feedback bot that will give feedback to ${learnerName} who prefers to use the pronoun ${learnerPronouns}. The question the teacher has asked is "${question}". Always include one positive about their work. Always include one thing to improvement upon. Do not be too vague. Draw upon points from the text given so that it feels more personal. Always use British English without z's. ${customPrompts}`,
                    },
                ],
                max_tokens: 2000,
                temperature: 0.8,
                presence_penalty: 0.6,
            });

            const completion = response.data.choices[0].message.content.trim();
            setCompletion(completion);
        } catch (error) {
            console.error('Error:', error.message);
            if (error.response && error.response.status === 429) {
                setError('Too many requests. Please wait and try again...');
            } else {
                setError('An error occurred. Please try again...');
            }
        }

        setIsLoading(false);
        setUserInput('');
    };

    const autofill = () => {
        setCustomPrompts("Give 2 points for improvement. Speak like Shakespeare");
        setLearnerName("Jordan");
        setLearnerPronouns("he");
        setQuestion("What is the meaning of life");
        setUserInput("The meaning of life is a complex question, if I had to briefly summarise my thoughts: lord of the rings is life, and therefore the meaning of life must be Gandalf.");
    };

    const [showError, setShowError] = useState(false);

    useEffect(() => {
        let timer;
        if (error) {
            setShowError(true);
            timer = setTimeout(() => {
                setShowError(false);
                setError('');
            }, 3000);
        }
        return () => clearTimeout(timer);
    }, [error]);

    return (
        <div className="feedback-container">
            <div className="chat-container">
                <p>
                        This bot will automatically generate learner feedback based on the
                        question and prompts that you supply. Custom prompts can be used to
                        further tailor the responses, for example "speak like Shakespeare" - prompts do not have to be singular, for example "Give 2 points for improvement. Speak like Shakespeare" - custom prompts will supersede the default prompts conditional to if they would conflict.
                </p>
                <label>
                    <b>Custom prompts:</b>
                    <input
                        className="custom-prompts"
                        placeholder="Enter additional prompts or leave empty..."
                        type="text"
                        value={customPrompts}
                        onChange={(e) => setCustomPrompts(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    <b>Learner Name:</b>
                    <input
                        className="learner-name"
                        placeholder="Enter the learners name..."
                        type="text"
                        value={learnerName}
                        onChange={(e) => setLearnerName(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    <b>Learner Pronoun:</b>
                    <input
                        className="learner-pronoun"
                        placeholder="Learner's preferred pronoun i.e. he, she, they"
                        type="text"
                        value={learnerPronouns}
                        onChange={(e) => setLearnerPronouns(e.target.value)}
                    />
                </label>
                <br />
                <label>
                    <b>Question:</b>
                    <input
                        className="learner-question"
                        placeholder="Paste the question you asked the learner..."
                        type="text"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                    />
                </label>
                {conversation.map((message, index) => (
                    <p key={index}>
                        {message.role}: {message.content}
                    </p>
                ))}
                {completion && <p>Assistant: {completion}</p>}
            </div>
            <form id="chat-form" onSubmit={handleSubmit}>
                <textarea
                    className="chat-input"
                    placeholder="Paste the learner's answer to the above question and hit 'send'..."
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                />
                <button className="chat-send" type="submit">
                    Send
                </button>
                {isLoading && <div className='spinner-container'><div className="spinner"></div><p>Generating...</p></div>}
            </form>
            <input
                className="autofill"
                type="button"
                value="Autofill test case"
                onClick={autofill}
            />
            {error && (
                <p className={`gpt-error ${showError ? 'visible' : 'hidden'}`}>
                    <b>Error:</b> {error}
                </p>
            )}
        </div>
    );
}

export default Feedback;
