import React from 'react';
import './issuelog.css';

const Issuelog = () => {
  const issues = [
    { status: 'ACTIVE', title: 'GPT TTS transcription uncertainty', description: 'GPT TTS will, on occasion, not transcribe dependant on browser - regular TTS functionality working as intended - dependencies are occasionally failing to load on Firefox and Opera' },
    { status: 'SQUASHED', title: 'Feedback Generator anomalies', description: 'After prolonged usage some responses from the generator appear disconnected from the subject matter - fix implemented to change how context retention is handled, including minor changes to the default guidance prompts.' },
    { status: 'SQUASHED', title: 'TTS promise rejection', description: 'Uncaught runtime error - unknown promise rejection reason due to no source being found for the users voice recording prior to transcription - only effecting Firefox - fix implemented, modified handleVoiceData() to convert the blob to text without using the convertBlobToString() function.' },
  ];

  return (
    <div className="issue-container">
      {issues.map((issue, index) => (
        <div key={index}>
          <h3>{issue.status} - {issue.title}</h3>
          <p>{issue.description}</p>
        </div>
      ))}
    </div>
  );
};

export default Issuelog;
