import '../App.css';
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import GPTFeedback from './Feedback';
import TFVision from './Compvision';
import GPTTTS from './ChatWindow';
import LiveCSS from './CSSWindow';
import Knownissues from './Issuelog';
import Enigma from './Enigma';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import image1 from '../images/project1-image.jpg';
import image2 from '../images/project2-image.jpg';
import image3 from '../images/project3-image.jpg';
import image4 from '../images/project4-image.jpg';
import image5 from '../images/project5-image.jpg';
import image6 from '../images/project6-image.jpg';

const Feedback = () => {
  return <div><GPTFeedback /></div>;
};

const CompVision = () => {
  return <div><TFVision /></div>;
};

const ChatTTS = () => {
  return <div><GPTTTS /></div>;
};

const CSSWindow = () => {
  return <div><LiveCSS /></div>;
};

const EnigmaTab = () => {
  return <div><Enigma /></div>;
};

const Issuelog = () => {
  return <div><Knownissues /></div>;
};

const CustomProjectComponent = ({ project }) => {
  return (
    <div>
      <project.component />
    </div>
  );
};

const ProjectDetails = ({ project }) => {
  return (
    <div className="project-details">
      {project && <CustomProjectComponent project={project} />}
    </div>
  );
};

function AppMain() {
  const [selectedProject, setSelectedProject] = useState(null);

  const projects = [
    { id: 1, title: 'Computer Vision', description: 'Image, object, and entity recognition plugged into a web-app via TensorFlow.js', component: CompVision, image: image1 },
    { id: 2, title: 'Feedback Generator', description: 'A learner feedback generator which takes custom prompts to generate personalised feedback', component: Feedback, image: image2 },
    { id: 3, title: 'GPT TTS', description: 'A custom implementation of ChatGPT which takes verbal input and produces verbal output', component: ChatTTS, image: image3 },
    { id: 4, title: 'Non-ML: Live CSS', description: 'A CSS tester panel with real-time feedback', component: CSSWindow, image: image4 },
    { id: 5, title: 'Non-ML: Enigma', description: 'A JS based Enigma machine', component: Enigma, image: image5 },
    { id: 6, title: 'Issue Log', description: 'Ongoing and past issues', component: Issuelog, image: image6 },
  ];

  const handleProjectClick = (project) => {
    setSelectedProject(project);
  };

  return (
    <div className='main-container'>
      <Router>
        <Routes>
          <Route path='/feedback' exact element={<Feedback />} />
        </Routes>
      </Router>
      <div className="sidebar">
        <div className="sidebar-icon">
          <a href="https://github.com/JMCromp" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </div>
        <div className="sidebar-icon">
          <a href="https://www.linkedin.com/in/jordanmarkcrompton/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
        </div>
      </div>
      <div className="portfolio-container">
        <div className={`glassmorphism-card ${selectedProject ? 'shifted-left' : ''}`}>
          <h1>>_</h1>
          <p>A PoC, experimental, Machine Learning (mostly) toolkit:</p>
          <div className="project-list">
            {projects.map((project) => (
              <div
                className="project-card"
                key={project.id}
                onClick={() => handleProjectClick(project)}
              >
                <div className="project-front">
                  <img src={project.image} alt={project.title} />
                </div>
                <div className="project-back">
                  <p><b>{project.title}</b></p>
                  <p>{project.description}</p>
                </div>
              </div>
              
            ))}
          </div>
          <p>This app is in no way responsive, best viewed on desktop.</p>
        </div>
        {selectedProject && <ProjectDetails project={selectedProject} />}
      </div>
    </div>
  );
}

export default AppMain;
