import React, { useState } from 'react';
import './csswindow.css';

const CSSWindow = () => {
  const [css, setCSS] = useState(`display: flex; 
justify-content: center; 
align-items: center;
flex-direction: column;
gap: 20px;
border-radius: 10px; `);
  const [html, setHTML] = useState(`testing 1..2..3...
<img src="https://pbs.twimg.com/media/FNCoeUhXMAIA23l.jpg" style="width: 300px; border-radius: 10px;">`);

  const handleCSSChange = (event) => {
    setCSS(event.target.value);
  };

  const handleHTMLChange = (event) => {
    setHTML(event.target.value);
  };

  return (
    <div className='CSSgen-container'>
      <div className='CSS-container'>
        <div className='input-container'>
          <label className='input-label'>CSS</label>
          <textarea className='CSS-input' value={css} onChange={handleCSSChange} />
        </div>
        <div className='input-container'>
          <label className='input-label'>Text/HTML</label>
          <textarea className='HTML-input' value={html} onChange={handleHTMLChange} />
        </div>
      </div>
      <div className='output-container'>
        <div className="CSS-output" style={{ ...parseCSS(css) }} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
};

const parseCSS = (css) => {
  const styles = {};

  css.split(';').forEach((rule) => {
    const [property, value] = rule.split(':');
    if (property && value) {
      styles[property.trim()] = value.trim();
    }
  });

  return styles;
};

export default CSSWindow;
